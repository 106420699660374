import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    
    <p>You've reached the website of Geoff Petrie.</p>
    <p>This site is here so that I have some sort of web presence.</p>
    <p>Professionally, I am a software engineer and an engineering manager.</p>
    
    <h3>Additional Details:</h3>
    <p><strong>General Resume:</strong> <a href="https://www.linkedin.com/in/geoffreypetrie">LinkedIn</a></p>
    <p><strong>Github:</strong> <a href="https://github.com/geopet">https://github.com/geopet</a></p>
    <p><strong>Twitter</strong> <em>(mainly defunct as of the moment)</em>: <a href="https://twitter.com/geopet">@geopet</a></p>
    <p><strong>Email:</strong> <a href="mailto:hello@geoffpetrie.com">hello@geoffpetrie.com</a></p>
  </Layout>
)

export default IndexPage
